import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const OutputPage = () => {
    const [feedback, setFeedback] = useState(null);
    const userUID = useSelector((state) => state.auth.user.uid);
    const location = useLocation();
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        // Extracting the file URL from the query parameter
        const query = new URLSearchParams(location.search);
        const file = query.get('file');
        if (file) {
            setFileUrl(decodeURIComponent(file));
        }
    }, [location]);

    const handleDownload = () => {
        if (fileUrl) {
            // Trigger file download
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'customized_resume.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert("No file available for download.");
        }
    };

    const submitFeedback = async (like) => {
        setFeedback(like);

        const feedbackData = {
            uid: userUID,
            feedback: like ? 'like' : 'dislike',
        };

        try {
            const response = await axios.post('/submit_feedback', feedbackData);
            if (response.data.success) {
                alert('Feedback submitted successfully.');
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            alert('An error occurred while submitting your feedback.');
        }
    };

    return (
        <div className="flex flex-col h-screen w-screen overflow-auto">
            <div className='flex-grow'>
                <Navbar black={true} login={false} loggedIn={true} />

                <div className='flex flex-col md:mt-40 md:flex-row justify-center items-center gap-28'>
                    <div
                        className="md:w-[637px] md:h-[607px] rounded-[50px] rotate-[-15.58deg] opacity-80 bg-black bg-cover bg-[50%_50%]"
                        style={{ backgroundImage: "url('/frame-1.png')" }}
                    />
                    <div className='flex flex-col justify-center items-center gap-6'>
                        <p className="text-center font-bold tracking-[0] leading-[normal] w-[300px] md:w-[584px] [font-family:'Cabin',Helvetica] text-black text-[35px] md:text-[45px]">
                            Download Your Customized CV and Get Your Dream Job!
                        </p>

                        <button
                            className="flex justify-center items-center gap-6 w-[368px] h-[75px] bg-[#19506e] rounded-[20px]"
                            onClick={handleDownload}
                        >
                            <p className="[font-family:'Cabin',Helvetica] font-bold text-white text-[35px] tracking-[0] leading-[normal]">
                                Download
                            </p>
                            <img className="w-[35px] h-[35px]" alt="Download Icon" src="frame.svg" />
                        </button>

                        <div className="flex flex-col w-[368px] justify-center items-center gap-4 mt-4 border border-dashed">
                            <p>Satisfied with the outcome?</p>
                            <div className='flex gap-6'>
                                <button
                                    className={`p-2 rounded-full ${feedback === true ? 'text-green-500' : 'text-gray-400'}`}
                                    onClick={() => submitFeedback(true)}
                                >
                                    <FaThumbsUp className="w-6 h-6" />
                                </button>
                                <button
                                    className={`p-2 rounded-full ${feedback === false ? 'text-red-500' : 'text-gray-400'}`}
                                    onClick={() => submitFeedback(false)}
                                >
                                    <FaThumbsDown className="w-6 h-6" />
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default OutputPage;
