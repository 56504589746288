import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { auth } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Signup = () => {
    const [formData, setFormData] = useState({
        fName: "",
        lName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            toast.error("Passwords do not match. Please try again.", { autoClose: 5000 });
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                formData.email,
                formData.password
            );
            const user = userCredential.user;
            console.log(user.uid)
            const userData = {
                uid: user.uid,
                fName: formData.fName,
                lName: formData.lName,
                email: formData.email,
            };

            axios.post('/add_user', userData)
                .then(response => {
                    console.log(response.data);
                    toast.success('Registration successful. Redirecting to login...', { autoClose: 5000 });
                    setTimeout(() => {
                        navigate("/login");
                    }, 3000);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            toast.success('Registration successful. Redirecting to login...', { autoClose: 5000 });
            setTimeout(() => {
                navigate("/login");
            }, 3000);
        } catch (error) {
            // Parsing Firebase auth errors for more user-friendly messages
            let errorMessage = "Registration failed. Please try again.";
            if (error.code === 'auth/email-already-in-use') {
                errorMessage = "This email is already in use. Please try another one.";
            } else if (error.code === 'auth/weak-password') {
                errorMessage = "The password is too weak. Please use a stronger password.";
            } else if (error.code === 'auth/invalid-email') {
                errorMessage = "The email address is not valid. Please check it and try again.";
            }

            toast.error(errorMessage, { autoClose: 5000 });
        }
    };

    return (
        <div className="flex flex-col items-center w-full px-4 md:px-20 py-10 md:mt-[-170px]">
            <div className="w-full max-w-md bg-[#f7f6f2] rounded border shadow-md p-6">
                <h2 className="text-2xl font-medium text-[#342f19] mb-4 text-center">
                    SIGN UP
                </h2>
                <form className="space-y-4" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="fName"
                        placeholder="First Name"
                        className="w-full p-2 border rounded"
                        value={formData.fName}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        name="lName"
                        placeholder="Last Name"
                        className="w-full p-2 border rounded"
                        value={formData.lName}
                        onChange={handleInputChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="w-full p-2 border rounded"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="w-full p-2 border rounded"
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                    <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className="w-full p-2 border rounded"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                    />
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="w-1/3 bg-[#2b2826] text-white rounded py-2 mt-4"
                        >
                            SIGN UP
                        </button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Signup;