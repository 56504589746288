import React from 'react';
import Navbar from "../components/Navbar";
import Signup from '../components/Signup';
import Footer from '../components/Footer';

const SignupPage = () => {
    return (
        <div className='flex flex-col w-screen h-screen justify-between'>
            <div className="flex w-full">
                <Navbar black={true} login={false} loggedIn={false} />
            </div>

            <Signup />
            <Footer />
        </div>
    )
}

export default SignupPage