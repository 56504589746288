import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "../components/LanguageSwitcher";

import { useDispatch } from 'react-redux';
import { logout } from "../actions/authActions";
import { getAuth, signOut } from "firebase/auth";


const Navbar = ({ black, login, loggedIn }) => {
    const { t } = useTranslation();
    const logoSrc = black ? "/logo-black.png" : "/logo.png";
    const textColorClass = black ? "text-black hover:text-gray-700" : "text-white hover:text-gray-300";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            dispatch(logout());
            navigate('/login');
        }).catch((error) => {
            console.error("Logout failed", error);
        });
    };

    return (
        <div className="flex justify-between items-center px-8 py-4">
            <div>
                <Link to="/">
                    <img src={logoSrc} alt="Logo" className='pt-3 h-[200px] w-[200px]' />
                </Link>
            </div>
            <div className='flex gap-3'>
                {!login &&
                    (loggedIn ?
                        <button onClick={handleLogout} className={`font-bold ${textColorClass}`}>
                            {t('logout_nav')}
                        </button> :
                        <Link to="/login" className={`font-bold ${textColorClass}`}>
                            {t('login_nav')}
                        </Link>
                    )
                }
                <LanguageSwitcher textColorClass={textColorClass} />
            </div>
        </div>
    );
}

export default Navbar;
