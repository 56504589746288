import React from 'react'
import ResetPassword from '../components/ResetPassword'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'


const ResetPasswordPage = () => {
    return (
        <div className='flex flex-col justify-between w-screen h-screen'>
            <div className="flex w-full">
                <Navbar black={true} login={true} loggedIn={true} />
            </div>

            <ResetPassword />

            <Footer />

        </div>
    )
}

export default ResetPasswordPage