import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginSuccess } from './actions/authActions';

const AppInitializer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            dispatch(loginSuccess(JSON.parse(userData)));
        }
    }, [dispatch]);

    return null;
};

export default AppInitializer;
