import React, { useState } from "react";
import { auth } from '../firebase';
import { sendPasswordResetEmail } from "firebase/auth";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const [loginData, setLoginData] = useState({
        email: ''
    });

    const changePassword = (event) => {
        event.preventDefault();

        sendPasswordResetEmail(auth, loginData.email)
            .then(() => {
                toast.success("Password Reset Email Sent!");
            })
            .catch((error) => {
                let errorMessage;
                switch (error.code) {
                    case 'auth/invalid-email':
                        errorMessage = "The email address is not valid.";
                        break;
                    case 'auth/user-not-found':
                        errorMessage = "No user found with this email address.";
                        break;
                    default:
                        errorMessage = "An error occurred. Please try again later.";
                }
                toast.error(errorMessage);
            });
    }

    return (
        <div className="flex flex-col items-center w-full px-4 md:px-20 py-10">
            <div className="w-full max-w-md bg-[#f7f6f2] rounded border shadow-md p-6">
                <h2 className="text-2xl font-medium text-[#767676] mb-4 text-center">
                    Forgot Your Password?
                </h2>
                <h3 className="text-[#767676] mb-4 text-center">Enter your email and we'll send you a link to reset your password.</h3>
                <form className="space-y-4" onSubmit={changePassword}>
                    <input
                        type="email"
                        placeholder="Email"
                        className="w-full p-2 border rounded"
                        value={loginData.email}
                        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                    />
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="w-full bg-black text-white rounded py-2 mt-4"
                        >
                            Send Email
                        </button>
                    </div>
                    <p className="text-black text-[13px] text-center">
                        <a href="/login" className="underline">Back to Log In Page</a>
                    </p>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ResetPassword;
