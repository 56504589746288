import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';



export const LandingPage = () => {
    const { t } = useTranslation();


    return (
        <div className="flex flex-col h-screen w-screen overflow-auto">
            <div className='flex-grow'>
                <video
                    autoPlay
                    loop
                    muted
                    className="absolute top-1/2 left-1/2 min-w-full min-h-full -translate-x-1/2 -translate-y-1/2 object-cover z-0"
                >
                    <source src="/bg.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-80 z-10"></div>
                <div className="relative z-20 text-white w-full">
                    <Navbar black={false} login={false} loggedIn={false} />

                    <div className="flex flex-col mt-10  gap-10 px-6 md:px-28 md:mt-40 items-center justify-between text-white">
                        <p className="h1 font-extrabold text-center md:text-4xl">
                            {t('landingpage_sentence1')}
                        </p>
                        <p className="font-bold text-center md:text-3xl">
                            {t('landingpage_sentence2')}

                        </p>
                        <Link to="/action">
                            <button className="font-bold md:mt-28 md:text-3xl bg-slate-700 rounded-2xl px-16 md:px-60 py-4">
                                {t('getCustomizedCV')}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="relative z-50">
                <Footer />
            </div>

        </div>
    );
};
