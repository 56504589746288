import React from 'react';
import { useTranslation } from 'react-i18next';

const FileUploader = ({ id, onFileSelect }) => {
  const { t } = useTranslation();

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 1024 * 1024 * 10) {
      alert("File size cannot exceed more than 10MB");
    } else {
      onFileSelect(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.size > 1024 * 1024 * 10) {
      alert("File size cannot exceed more than 10MB");
    } else {
      onFileSelect(file);
    }
  };

  return (
    <div className="flex flex-col border border-dashed border-black md:w-[533px] h-[100px] items-center gap-2 rounded-[10px] justify-center p-1 m-2">
      <div className='flex justify-center items-center gap-5'
        onDragOver={handleDragOver}
        onDrop={handleDrop}>
        <img alt="upload cloud" src="upload-cloud.svg" />
        <div className="flex flex-col items-center justify-center gap-[12px]">
          <label htmlFor={id} className="cursor-pointer">
            <p className='text-[12px] md:text-sm'>{t('SelectFileDragDropHere')}</p>
            <p className='text-[10px] md:text-[13px] text-black/50'>{t('WordPDF')}</p>
          </label>
          <input
            type="file"
            id={id}
            style={{ display: 'none' }}
            onChange={handleFileInput}
            accept=".doc,.docx,.pdf"
          />
        </div>
        <div>
          <button
            id="upload-button"
            className="border border-[#0f91d1] p-2 rounded-lg w-fit tracking-[0] text-[10px] text-[#0f91d1] font-normal leading-[normal] whitespace-nowrap cursor-pointer"
            onClick={() => document.getElementById(id).click()}
          >
            {t('SELECTFILE')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
