import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import AppInitializer from './AppInitializer';
import PrivateRoute from './components/PrivateRoute';
import { LandingPage } from "./pages/LandingPage";
import ActionPage from './pages/ActionPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import OutputPage from './pages/OutputPage';
import ResetPasswordPage from './pages/ResetPasswordPage';


function App() {


  return (
    <div>
      <Provider store={store}>
        <Router>
          <AppInitializer />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/action" element={<PrivateRoute><ActionPage /></PrivateRoute>} />
            <Route path="/output" element={<PrivateRoute><OutputPage /></PrivateRoute>} />
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
