import React from 'react'

const Footer = () => {
    return (
        <footer className="bg-black p-4 text-center">
            <p className="text-white">
                Iter Innovandi &copy; {new Date().getFullYear()}. All rights reserved.
            </p>
        </footer>
    );
}

export default Footer