import React, { useState, useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { auth, provider } from "../firebase";
import {
    signInWithEmailAndPassword,
    signInWithRedirect,
    getRedirectResult,
} from "firebase/auth";

import { useDispatch } from "react-redux";
import { loginSuccess, loginFailure } from "../actions/authActions";

const LogIn = () => {
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const loginUser = async (e) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, loginData.email, loginData.password)
            .then((userCredential) => {
                console.log(userCredential)
                dispatch(loginSuccess(userCredential.user));
                toast.success('Login successful', { autoClose: 10000 });
                navigate("/action");
            })
            .catch((error) => {
                console.log(error)
                toast.error("Login failed. Please try again.", { autoClose: 10000 });
                dispatch(loginFailure());
            });
    };

    useEffect(() => {
        getRedirectResult(auth)
            .then(async (result) => {
                if (result) {
                    const user = result.user;
                    console.log(user)
                    const userData = {
                        uid: user.uid,
                        fName: user.displayName.split(' ')[0], // Assuming the first part of the displayName is the first name
                        lName: user.displayName.split(' ').slice(1).join(' '), // Rest is considered as the last name
                        email: user.email,
                    };

                    axios.post('/add_user', userData)
                        .then(response => {
                            console.log(response.data);
                            dispatch(loginSuccess(user));
                            navigate("/action");
                        })
                        .catch(error => {
                            console.log('Error during Google sign-in:', error);
                            toast.error("Google sign-in error. Please try again.", { autoClose: 10000 });
                            dispatch(loginFailure());
                        });
                }
            })
            .catch((error) => {

            });
    }, [navigate, dispatch]);

    const googleSignIn = async () => {
        try {
            await signInWithRedirect(auth, provider);
        } catch (error) {
            console.log(error)
            toast.error("Google sign-in error. Please try again.", { autoClose: 10000 });
            dispatch(loginFailure());

        }
    };

    return (
        <div className="flex flex-col items-center w-full px-4 md:px-20 py-10 md:mt-[-170px]">
            <div className="w-full max-w-md bg-[#f7f6f2] rounded border shadow-md p-6">
                <h2 className="text-2xl font-medium text-[#767676] mb-4 text-center">
                    {t('login')}
                </h2>
                <form className="space-y-4" onSubmit={loginUser}>
                    <input
                        type="text"
                        placeholder={t('email')}
                        className="w-full p-2 border rounded"
                        value={loginData.email}
                        onChange={(e) =>
                            setLoginData({ ...loginData, email: e.target.value })
                        }
                    />
                    <input
                        type="password"
                        placeholder={t('password')}
                        className="w-full p-2 border rounded"
                        value={loginData.password}
                        onChange={(e) =>
                            setLoginData({ ...loginData, password: e.target.value })
                        }
                    />
                    <div className="flex items-center gap-2 text-[#767676]">
                        <input type="checkbox" />
                        <span>{t('rememberMe')}</span>
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="w-full bg-[#2b2826] text-white rounded py-2 mt-4"
                        >
                            {t('login')}
                        </button>
                    </div>
                    <div className="flex justify-between">
                        <div className="text-[#2b2826] text-[13px]">
                            <Link to={"/signup"}>
                                <p className="underline">
                                    {t('dontHaveAnAccount')}
                                </p>
                            </Link>
                        </div>
                        <div className="text-[#2b2826] text-[13px]">
                            <Link to="/resetpassword" className="underline">
                                {t('forgotPassword')}
                            </Link>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <p className="text-[#2b2826] text-[13px]">{t('or')}</p>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="flex justify-center w-full bg-[#ffffff] text-[#767676] rounded border py-2 mt-4"
                            onClick={googleSignIn}
                        >
                            <FcGoogle
                                size={30}
                                style={{ marginRight: "15px", marginLeft: "20px" }}
                            />
                            {t('logInWithGoogle')}
                        </button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default LogIn;
