import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    // English translations go here
                    login: "LOG IN",
                    login_nav: "Login",
                    logout_nav: "LogOut",
                    email: "Email",
                    password: "Password",
                    rememberMe: "Remember Me",
                    dontHaveAnAccount: "Don’t have an account?",
                    forgotPassword: "Forgot password?",
                    or: "OR",
                    logInWithGoogle: "LOG IN WITH GOOGLE",
                    logInWithFacebook: "LOG IN WITH FACEBOOK",

                    landingpage_sentence1: "Customizing Your Path to Professional Success",
                    landingpage_sentence2: "Instantly optimize your CV for your target job, enhancing your employment opportunities with a simple and stress-free process.",
                    getCustomizedCV: "Get A Customized CV",

                    UploadYourCVHere: "Upload Your CV Here !",
                    UploadedFile: "Uploaded File:",
                    UploadAJobDescriptionHere: "Upload A Job Description Here!",
                    OR: "OR",
                    WriteJobDescriptionHere: "Write the job description here...",
                    GenerateCV: "Generate CV",

                    SelectFileDragDropHere: "Select a file or drag and drop here",
                    WordPDF: "Word or PDF, file size no more than 10MB",
                    SELECTFILE: "SELECT FILE",



                },

            },
            fr: {
                translation: {
                    // French translations go here
                    login: "CONNEXION",
                    login_nav: "Connexion",
                    logout_nav: "Se déconnecter",
                    email: "E-mail",
                    password: "Mot de passe",
                    rememberMe: "Se souvenir de moi",
                    dontHaveAnAccount: "Vous n'avez pas de compte ?",
                    forgotPassword: "Mot de passe oublié ?",
                    or: "OU",
                    logInWithGoogle: "SE CONNECTER AVEC GOOGLE",
                    logInWithFacebook: "SE CONNECTER AVEC FACEBOOK",

                    landingpage_sentence1: "Personnaliser votre chemin vers le succès professionnel",
                    landingpage_sentence2: "Optimisez instantanément votre CV pour le poste cible, améliorant vos opportunités d'emploi avec un processus simple et sans stress.",
                    getCustomizedCV: "Obtenez un CV personnalisé",


                    UploadYourCVHere: "Téléchargez votre CV ici !",
                    UploadedFile: "Fichier téléchargé :",
                    UploadAJobDescriptionHere: "Téléchargez une description de poste ici !",
                    OR: "OU",
                    WriteJobDescriptionHere: "Écrivez la description du poste ici...",
                    GenerateCV: "Générer un CV",

                    SelectFileDragDropHere: "Sélectionnez un fichier ou glissez-déposez ici",
                    WordPDF: "Word ou PDF, taille de fichier ne dépassant pas 10 Mo",
                    SELECTFILE: "SÉLECTIONNER UN FICHIER",






                },
            },
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;