import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import Axios from 'axios';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';
import FileUploader from '../components/FileUploader';
import Footer from '../components/Footer';

const ActionPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [cvFile, setCvFile] = useState(null);
    const [jobDescriptionFile, setJobDescriptionFile] = useState(null);
    const [jobDescriptionText, setJobDescriptionText] = useState('');
    const [isLoading, setIsLoading] = useState(false);



    const handleSubmit = async () => {
        if (!cvFile || (!jobDescriptionFile && !jobDescriptionText.trim())) {
            alert("Please upload a CV and either upload a Job Description file or write the description.");
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        formData.append('cv', cvFile);
        if (jobDescriptionFile) {
            formData.append('jobDescriptionFile', jobDescriptionFile);
        }
        formData.append('jobDescriptionText', jobDescriptionText);

        try {
            const initResponse = await Axios.post('/customize_resume', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const taskId = initResponse.data.task_id;

            const checkStatus = async () => {
                const statusResponse = await Axios.get(`/task/${taskId}`);
                if (statusResponse.data.state === 'SUCCESS') {
                    const customResumeFile = await Axios.get(`/download/${taskId}`, { responseType: 'blob' });
                    setIsLoading(false);
                    console.log(customResumeFile);
                    const fileURL = window.URL.createObjectURL(new Blob([customResumeFile.data], { type: 'application/pdf' }));
                    console.log(fileURL);
                    navigate(`/output?file=${encodeURIComponent(fileURL)}`);

                } else if (statusResponse.data.state === 'PENDING') {
                    setTimeout(checkStatus, 2000); // Poll again
                } else {
                    console.log(statusResponse.data.state)
                    setIsLoading(false);
                    alert("An error occurred while processing your request.");
                }
            };

            checkStatus();
        } catch (error) {
            setIsLoading(false);
            console.error('Error:', error);
            alert("An error occurred while starting your request.");
        }
    };

    return (
        <div className='flex flex-col h-screen w-screen overflow-auto'>
            <div className='flex-grow'>

                {isLoading && (
                    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center z-50">
                        <div className="flex flex-col items-center">
                            <FaSpinner className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue-500" />
                            <div className="text-lg font-medium">{t('Generating')}</div>
                        </div>
                    </div>
                )}

                <div><Navbar black={true} login={false} loggedIn={true} /></div>

                <div className='flex flex-col justify-center items-center mb-4 gap-10'>
                    <div className='flex flex-col md:flex-row'>
                        <div className='flex flex-col h-full justify-center items-center gap-2'>
                            <h1>{t('UploadYourCVHere')}</h1>
                            <FileUploader
                                id="cv-upload"
                                onFileSelect={(file) => setCvFile(file)}
                            />
                            {cvFile && (
                                <div>
                                    <p className='text-xs text-green-600'>{t('UploadedFile')} {cvFile.name}</p>
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col justify-center items-center gap-2'>
                            <h1>{t('UploadAJobDescriptionHere')}</h1>
                            <FileUploader
                                id="jd-upload"
                                onFileSelect={(file) => setJobDescriptionFile(file)}
                            />
                            {jobDescriptionFile && (
                                <div>
                                    <p className='text-xs text-green-600'>{t('UploadedFile')} {jobDescriptionFile.name}</p>
                                </div>
                            )}
                            <h1>{t('OR')}</h1>

                            <textarea
                                className="w-full md:w-[531px] h-[420px] bg-[#d9d9d947] rounded-[20px] p-5"
                                placeholder={
                                    t('WriteJobDescriptionHere')
                                }
                                value={jobDescriptionText}
                                onChange={(e) => setJobDescriptionText(e.target.value)}
                            />

                        </div>

                    </div>
                    <button
                        className="w-[300px] h-[50px] text-base md:w-[1140px] md:h-[70px] md:text-xl bg-[#174f6a] rounded-[20px] text-white font-extrabold "
                        onClick={handleSubmit}>

                        {t('GenerateCV')}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ActionPage