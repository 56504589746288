import React from 'react';
import Login from '../components/Login';
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';

const LoginPage = () => {
    return (
        <div className='flex flex-col justify-between w-screen h-screen'>
            <div className="flex w-full">
                <Navbar black={true} login={true} loggedIn={true} />
            </div>

            <Login />

            <Footer />


        </div>
    )
}

export default LoginPage