import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher({ textColorClass }) {
    const { i18n } = useTranslation();

    const changeLanguage = (languageCode) => {
        i18n.changeLanguage(languageCode);
    };

    return (
        <div className={`font-bold ${textColorClass}`}>
            <button onClick={() => changeLanguage('fr')}>FR</button> | <button onClick={() => changeLanguage('en')}>EN</button>
        </div>
    );
}

export default LanguageSwitcher;